
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { NavBar } from './components/navBar';
import { Footer } from './components/Footer';
import { Home } from './pages/home';
import { Projects} from './pages/Projects ';
import { Services } from './pages/Services';
import { ContactUs } from './pages/ContactUs';
import axios from 'axios';

import { useEffect } from 'react';



function App() {



  return (
    <div className="App">
      <Router>
        <div className='relative z-50 w-full'>
          <NavBar />
        </div>
        <div className='pages'>
          <Routes>
            <Route path='/' element={<Home />} ></Route>
            <Route path='/Projects' element={<Projects/>} ></Route>
            <Route path='/Services' element={<Services/>} ></Route>
            <Route path='/Contact' element={<ContactUs/>} ></Route>
          </Routes>

        
        </div>
      </Router>
    </div>
  );
}

export default App;


