import React, { useState, useEffect, useRef } from "react";
import { motion, easeInOut } from "framer-motion";
import axios from "axios";
import ModalBook from "../components/ModalBook";

import "./pages.css";

import { CardSimple } from "../components/CardSimple";
import { Accordion } from "../components/Accordion";
import { Footer } from "../components/Footer";
import { ProjectCard } from "../components/ProjectCard";
import Modal from "../components/modal";
import { NavLink, useHistory } from "react-router-dom";
import { VideoCard } from "../components/VideoCard";
import { VideoCardTest } from "../components/VideoCardTest";

import bgImg from "../images/bg-project.mp4";
import play from "../images/icon/Video_fill.png";

import EnvImg from "../images/icon/virtual-reality/service-360.png";
const localPath = process.env.REACT_APP_API_KEY;

const info = [
  { id: 1, projectLabel: "projectLabel1" },
  { id: 2, projectLabel: "projectLabel2" },
  { id: 6, projectLabel: "projectLabel3" },
  { id: 6, projectLabel: "projectLabel3" },
];

const itemVR = [
  {
    title: "What equipment is needed to view Virtual Reality Tours?",
    content:
      " Our tours are accessible on various devices, including VR headsets, desktops, and mobile devices. No special equipment is required, but VR headsets provide the most immersive experience.",
  },
  {
    title: "Can Virtual Reality Tours be customized to match our brand?",
    content:
      " Yes, we offer customization options to ensure tours align with your brand identity and stylepreferences.",
  },
  {
    title: "How long does it take to create a Virtual Reality Tour?",
    content:
      "  The timeline depends on the scope and complexity of the project. Contact us for a personalized quote and timeline estimation.",
  },
];
const itemAn = [
  {
    title:
      " What types of properties or venues are suitable for Animated Video Walkthroughs?",
    content:
      "  Our service is suitable for a wide range of properties and venues, including real estate listings,hotels, restaurants, event venues, and more.",
  },
  {
    title: "How long does it take to create an Animated Video Walkthrough?",
    content:
      " The timeline varies depending on the complexity of the project and the clients requirements.Contact us for a personalized quote and timeline estimation",
  },
  {
    title: " Can I provide input and feedback during the production process?",
    content:
      " Yes, we encourage client involvement throughout the production process to ensure the final animation meets your expectations and goals.",
  },
];
const itemPh = [
  {
    title: "What sets your 360 Walkthroughs a part?",
    content:
      " Our tours offer more than just a visual experience; they provide an immersive journey that allows viewers to explore spaces as if they were there in person.",
  },
  {
    title: " How do you ensure quality and consistency?",
    content:
      " We maintain strict quality standards and employ a cohesive approach to ensure consistency across all our 360 Walkthroughs.",
  },
  {
    title: " Can I request customizations or additional features?",
    content:
      " Absolutely! We welcome your input and strive to accommodate any specific requests or enhancements to tailor the tours to your needs.",
  },
];
const itemVs = [
  {
    title: " What sets your Video & Photo Shoot service a part from others?",
    content:
      " Our team combines technical expertise with creative flair to produce visually stunning and emotionally compelling content that resonates with audiences.",
  },
  {
    title: " Can I request a specific style or theme for my shoot?",
    content:
      "Absolutely! We encourage clients to share their ideas and preferences with us so we can tailor the shoot to match their vision and brand identity.",
  },
  {
    title: " How long does it take to receive the final deliverables?",
    content:
      " Delivery times may vary depending on the scope of the project, but we strive to provide quick turnaround times without compromising on quality. Well provide you with a clear timeline during the initial consultation.",
  },
  {
    title:
      "Do you offer additional services such as drone footage or live streaming?",
    content:
      "Yes, we offer a range of additional services to enhance your shoot, including drone footage for aerial perspectives and live streaming for real-time event coverage. Just let us know your requirements, and well tailor our services accordingly.",
  },
];
const VrDegree = {
  head: "360-Degree Views",
  body: "Enjoy panoramic views of every corner, allowing for a comprehensive exploration of the space from every angle.",
};

const VrImmersive = {
  head: "Immersive 3D Environments",
  body: " Explore lifelike spaces with stunning 3D visuals that transport you to the heart of the experience.",
};
const VrCustomization = {
  head: "Customization Options",
  body: "  Tailor the virtual tour to match your branding and style, creating a cohesive and professional presentation.",
};
const VrMultiPlatform = {
  head: "Multi-Platform Compatibility",
  body: " Access virtual tours across various devices, including desktop computers, mobile phones, and VR headsets.",
};

const AnDynamic = {
  head: " Dynamic Animation",
  body: " Transform static images and floor plans into dynamic, animated walkthroughs that captivate audiences.",
};
const AnDetailed = {
  head: "  Detailed Visualization",
  body: "Highlight key features and selling points of properties or venues with detailed visualizations and animations.",
};
const AnNarrative = {
  head: " Narrative Storytelling    ",
  body: "Craft compelling narratives that guide viewers through spaces, evoking emotion and fostering a connection.",
};
const AnCompatibility = {
  head: "Compatibility",
  body: "Compatible with various platforms and devices, including websites, social media, and presentations, maximizing reach and accessibility.",
};

const VsProfessional = {
  head: " Expert Craftsmanship",
  body: " Our videographers and photographers bring years of experience and a keen eye for detail to every shoot, ensuring each frame is a masterpiece in its own right.",
};
const VsCreative = {
  head: " Tailored Creativity",
  body: " Whether it is a whimsical product shoot or a high-energy event coverage, we tailor our approach to reflect your brands personality and objectives.",
};
const VsPost = {
  head: "  Seamless Delivery",
  body: "  From planning to post-production, we handle every aspect of the process with professionalism and efficiency.",
};

const VsDelivery = {
  head: " Versatile Deliverables",
  body: " Receive your final deliverables in a variety of formats, optimized for different platforms and purposes.",
};

const PhCreative = {
  head: "Virtual Site Visits",
  body: "Explore properties from anywhere with our Virtual Site Visits. Navigate in 360-degree detail, immersing yourself in every angle.    ",
};
const PhTailored = {
  head: " 3D Superimposition",
  body: "Visualize changes with our 3D superimposition. Seamlessly integrate 3D elements into environments for an immersive experience.",
};
const PhEnhanced = {
  head: "  Multi-Platform Compatibility",
  body: " Access tours from any device with a simple URL. Compatible across desktops, laptops, tablets, and smartphones.",
};
const PhFlexible = {
  head: " Interactive Hotspots",
  body: " Engage with hotspots for added context. From text to images and videos, enhance your viewing experience.",
};

export const Services = () => {
  const textToType = "Explore our Service";
  const [displayText, setDisplayText] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenAV, setIsOpenAV] = useState(false);
  const [isOpenPh, setIsOpenPh] = useState(false);
  const [isOpenVs, setIsOpenVs] = useState(false);
  const [datavr, setDataVr] = useState([]);
  const [VideoItemAN, setVideoANItem] = useState([]);
  const [VideoItemPH, setVideoPHItem] = useState([]);
  const [VideoItemVS, setVideoVSItem] = useState([]);
  const [showBookDemo, setShowBookDemo] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    const hash = window.location.hash;

    if (hash == "#VirtualReality") {
      const element = document?.querySelector(hash);
      setIsOpen(true);
      setIsOpenAV(false);
      setIsOpenPh(false);
      setIsOpenVs(false);
      element.scrollIntoView({ top: 0, behavior: "smooth" });
    } else if (hash == "#AnimatedVideos") {
      const element = document?.querySelector(hash);
      console.log("true");
      setIsOpenAV(true);
      setIsOpen(false);
      setIsOpenPh(false);
      setIsOpenVs(false);
      element.scrollIntoView({ top: 0, behavior: "smooth" });
      if (DivRef.current) {
        DivRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else if (hash == "#VideoShoot") {
      const element = document.querySelector(hash);
      setIsOpenVs(true);
      setIsOpen(false);
      setIsOpenPh(false);
      setIsOpenAV(false);
      element.scrollIntoView({ top: 0, behavior: "smooth" });
      if (DivRef.current) {
        DivRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else if (hash == "#PhotoShoot") {
      const element = document.querySelector(hash);
      setIsOpenPh(true);
      setIsOpen(false);
      setIsOpenAV(false);
      setIsOpenVs(false);
      element.scrollIntoView({ top: 0, behavior: "smooth" });
      if (DivRef.current) {
        DivRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }

    const LiveData = localStorage?.getItem("LiveData");

    if (LiveData === undefined) {
      setDataVr([]);
    } else {
      const LiveDatas = JSON?.parse(LiveData);
      setDataVr(
        LiveDatas?.filter((item) => item.service_id == 1)
          .reverse()
          .slice(0, 4)
      );
      setVideoPHItem(
        LiveDatas?.filter((item) => item.service_id == 2)
          .reverse()
          .slice(0, 4)
      );
      setVideoANItem(
        LiveDatas?.filter((item) => item.service_id == 4)
          .reverse()
          .slice(0, 4)
      );
      setVideoVSItem(
        LiveDatas?.filter((item) => item.service_id == 3)
          .reverse()
          .slice(0, 4)
      );
    }
  }, []);

  const DivRef = useRef(null);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setIsOpenAV(false);
    setIsOpenPh(false);
    setIsOpenVs(false);
  };
  const toggleAccordionAV = () => {
    setIsOpenAV(!isOpenAV);
    setIsOpen(false);
    setIsOpenPh(false);
    setIsOpenVs(false);

    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const toggleAccordionvs = () => {
    setIsOpenVs(!isOpenVs);
    setIsOpen(false);
    setIsOpenPh(false);
    setIsOpenAV(false);

    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const toggleAccordionPh = () => {
    setIsOpen(false);
    setIsOpenAV(false);
    setIsOpenVs(false);
    setIsOpenPh(!isOpenPh);
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= textToType?.length) {
        setDisplayText(textToType?.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100); // Adjust typing speed here (milliseconds)

    return () => clearInterval(typingInterval);
  }, []);
  const videoModalHandler = (data) => {
    setShowModal(true);
    setSelectedData(data);
  };
  return (
    <section className="service-page">
      <section className="banner">
        <div className="video-container sub-banner-video">
          <video autoplay loop muted className="object-cover" autoPlay>
            <source src={bgImg} type="video/mp4" />
          </video>
          <div className="black-overlay"></div>
        </div>
        <div className="container px-4 bg-body max-auto lg:px-20 width-max">
          <div className="grid grid-flow-col text-center lg:grid-cols-1 gird-cols-1">
            <div className="row-span-2">
              <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                {displayText}
              </motion.h1>
            </div>
          </div>
        </div>
      </section>
      <div className="services-vr">
        <section className="px-4 p-t-b lg:px-20">
          <div
            className="grid grid-cols-2 services-grid"
            ref={DivRef}
            id="VirtualReality"
          >
            <div className="accordion-header" onClick={toggleAccordion}>
              <span className="icon">{isOpen ? "-" : "+"}</span>
            </div>
            <div>
              <div
                className="services-accordion-head"
                onClick={toggleAccordion}
              >
                <h2 className="text-4xl">Virtual Reality Tours</h2>

                <h4>Explore Spaces Like Never Before</h4>
              </div>
              {isOpen && (
                <div ClassName="mx-6">
                  <div className="mb-6 md:w-4/5 ">
                    <p>
                      Experience an immersive exploration with our Virtual
                      Reality Tours. Our cutting-edge VR technology allows you
                      to experience spaces like never before, bringing real
                      estate listings, hospitality venues, and more, to life in
                      stunning detail. With Doorto360, you can captivate your
                      audience, drive engagement, and differentiate your brand
                      with unforgettable virtual experiences.
                    </p>
                  </div>
                  <div className="grid lg:grid-cols-4 lg:pr-9">
                    <CardSimple
                      Item={VrImmersive}
                      img={require("../images/icon/virtual-reality/3d.png")}
                    />
                    <CardSimple
                      Item={VrDegree}
                      img={require("../images/icon/virtual-reality/service-360.png")}
                    />
                    <CardSimple
                      Item={VrCustomization}
                      img={require("../images/icon/virtual-reality/customization.png")}
                    />
                    <CardSimple
                      Item={VrMultiPlatform}
                      img={require("../images/icon/virtual-reality/multi-platform.png")}
                    />
                  </div>
                  <div className=" step">
                    <div>
                      <h6 className="sub-head"> How do we do it?</h6>{" "}
                    </div>
                    <div className="grid gap-2 lg:grid-cols-2 lg:pr-9">
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 1</small>
                          </div>
                          <h4>Consultation</h4>
                        </div>

                        <p>
                          Westart by understanding your goals and objectives for
                          the virtual tour.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 2</small>
                          </div>
                          <h4>Content Capture</h4>
                        </div>

                        <p>
                          Our team collects relevant materials, such as photos,
                          videos, and floor plans.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 3</small>
                          </div>
                          <h4>Content Creation</h4>
                        </div>

                        <p>
                          Using state-of-the-art technology, we transform the
                          content into immersive 3D environments.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 4</small>
                          </div>
                          <h4>Final Delivery</h4>
                        </div>

                        <p>
                          Once reviewed and approved, we deliver the final
                          virtual tour ready for deployment across your
                          marketing channels.
                        </p>
                      </div>
                    </div>
                  </div>
                  {datavr?.length === 0 ? (
                    <></>
                  ) : (
                    <div className="part">
                      <div className="justify-between lg:flex ">
                        <h6 className="sub-head">
                          Explore Some of Our Recent Virtual Reality Projects
                        </h6>
                        <div className="flex justify-center ">
                          <div>
                            <button
                              onClick={BookDemoHandler}
                              className="btn btn-primary hide-sm"
                            >
                              Book A Demo
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="grid grid-cols-1 gap-2 mt-3 md:grid-cols-4 lg:gap-8">
                        {datavr.map((item, index) => (
                          <VideoCardTest cardInfo={item}></VideoCardTest>
                        ))}
                      </div> */}
                      <ProjectCard
                        cardInfo={datavr}
                        info="video"
                      ></ProjectCard>
                    </div>
                  )}
                  <div className="part">
                    <div className="cta-banner">
                      <h5>
                        Ready to experience the future of property showcasing
                        and hospitality exploration with
                        <span className="text-style"> Doorto360?</span>
                      </h5>
                      <div className="flex justify-center lg:justify-start ">
                        <button
                          className="btn btn-primary"
                          onClick={BookDemoHandler}
                        >
                          Book A Demo
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="part">
                    <div>
                      <h6 className="sub-head"> Frequently Asked Questions</h6>
                      <Accordion item={itemVR}></Accordion>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 services-grid" id="PhotoShoot">
            <div className="accordion-header" onClick={toggleAccordionPh}>
              <span className="icon">{isOpenPh ? "-" : "+"}</span>
            </div>
            <div>
              <div
                className="services-accordion-head"
                onClick={toggleAccordionPh}
              >
                <h2 className="text-4xl">360 Walkthroughs</h2>

                <h4>Explore Spaces in Unprecedented Detail</h4>
              </div>
              {isOpenPh && (
                <div ClassName="mx-6">
                  <div className="mb-6 md:w-4/5 ">
                    <p>
                      Through cutting-edge technology and creative expertise, we
                      transform spaces into captivating virtual experiences that
                      leave a lasting impression. Whether showcasing real estate
                      properties, hospitality venues, educational facilities, or
                      retail spaces, our tours inspire curiosity, drive
                      interest, and encourage action.
                    </p>
                  </div>
                  <div className="grid lg:grid-cols-4 lg:pr-9">
                    <CardSimple
                      Item={PhCreative}
                      img={require("../images/icon/photo/Creative.png")}
                    />
                    <CardSimple
                      Item={PhTailored}
                      img={require("../images/icon/photo/Tailored.png")}
                    />
                    <CardSimple
                      Item={PhEnhanced}
                      img={require("../images/icon/photo/Enhanced.png")}
                    />
                    <CardSimple
                      Item={PhFlexible}
                      img={require("../images/icon/photo/Flexible.png")}
                    />
                  </div>
                  <div className=" step">
                    <h6 className="sub-head"> How do we do it?</h6>
                    <div className="grid gap-2 lg:grid-cols-2 lg:pr-9">
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 1</small>
                          </div>
                          <h4>Consultation and Planning:</h4>
                        </div>

                        <p>
                          We begin by understanding your project requirements
                          and objectives, tailoring our approach to align with
                          your vision and timeline.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 2</small>
                          </div>
                          <h4>360° Capture</h4>
                        </div>

                        <p>
                          Our team conducts a comprehensive capture session,
                          meticulously documenting each space from multiple
                          angles to showcase its full potential.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 3</small>
                          </div>
                          <h4>Post-Production Enhancement</h4>
                        </div>

                        <p>
                          Utilizing advanced editing techniques, we enhance
                          colors, clarity, and composition to ensure each tour
                          is visually stunning and immersive.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 4</small>
                          </div>
                          <h4>Seamless Delivery</h4>
                        </div>

                        <p>
                          Upon completion, we deliver your finalized 360
                          Walkthroughs ready for integration into your website,
                          presentations, or marketing materials.
                        </p>
                      </div>
                    </div>
                  </div>
                  {VideoItemPH?.length === 0 ? (
                    <></>
                  ) : (
                    <div className="part">
                      <div className="justify-between lg:flex">
                        <h6 className="sub-head">
                          Explore Some of Our Recent 360 Walkthroughs Projects
                        </h6>
                        <div className="flex justify-center">
                          <div>
                            <NavLink
                              to="/Projects"
                              className="btn btn-secondary md:mt-5"
                            >
                              View more
                            </NavLink>
                          </div>
                        </div>
                      </div>

                      {/* <div className="grid grid-cols-1 gap-2 mt-3 md:grid-cols-4 lg:gap-8">
                        {VideoItemPH.map((item, index) => (
                          <VideoCardTest cardInfo={item}></VideoCardTest>
                        ))}
                        
                      </div> */}
                      <ProjectCard
                        cardInfo={VideoItemPH}
                        info="video"
                      ></ProjectCard>
                    </div>
                  )}

                  <div className="part">
                    <div>
                      <h6 className="sub-head"> Frequently Asked Questions</h6>
                      <Accordion item={itemPh}></Accordion>
                    </div>
                  </div>
                  <div className="part">
                    <div className="ph-banner">
                      <h5>
                        Experience the Future of Exploration With 360
                        Walkthroughs!
                      </h5>
                      <div className="flex justify-center">
                        <button
                          className="btn btn-primary"
                          onClick={BookDemoHandler}
                        >
                          Book A Demo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 services-grid" id="AnimatedVideos">
            <div className="accordion-header" onClick={toggleAccordionAV}>
              <span className="icon">{isOpenAV ? "-" : "+"}</span>
            </div>
            <div>
              <div
                className="services-accordion-head"
                onClick={toggleAccordionAV}
              >
                <h2 className="text-4xl">Animated Videos</h2>

                <h4>Transforming Spaces With Dynamic Visuals</h4>
              </div>
              {isOpenAV && (
                <div ClassName="mx-6">
                  <div className="mb-6 md:w-4/5 ">
                    <p>
                      Stand out in crowded markets with eye-catching animated
                      videos that showcase properties and venues in a unique and
                      compelling way. Our dynamic animations bring spaces to
                      life, providing viewers with engaging and immersive visual
                      experiences. Increase viewer engagement, retention, and
                      conversion rates with our innovative video solutions.
                    </p>
                  </div>
                  <div className="grid lg:grid-cols-4 lg:pr-9">
                    <CardSimple
                      Item={AnDynamic}
                      img={require("../images/icon/animation/Dynamic-animation.png")}
                    />
                    <CardSimple
                      Item={AnDetailed}
                      img={require("../images/icon/animation/DetailedVisualization.png")}
                    />
                    <CardSimple
                      Item={AnNarrative}
                      img={require("../images/icon/animation/NarrativeStorytelling.png")}
                    />
                    <CardSimple
                      Item={AnCompatibility}
                      img={require("../images/icon/animation/Compatibility.png")}
                    />
                  </div>
                  <div className=" step">
                    <div>
                      <h6 className="sub-head"> How do we do it?</h6>{" "}
                    </div>
                    <div className="grid gap-2 lg:grid-cols-2 lg:pr-9">
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 1</small>
                          </div>
                          <h4>Concept Development</h4>
                        </div>

                        <p>
                          Collaborate with our team to develop a concept and
                          storyboard for your animated video walkthrough.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 2</small>
                          </div>
                          <h4>Animation Production</h4>
                        </div>

                        <p>
                          Our talented animators bring the concept to life using
                          industry-leading animation software and techniques.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 3</small>
                          </div>
                          <h4>Review and Feedback</h4>
                        </div>

                        <p>
                          Review the draft animation and provide feedback for
                          revisions and adjustments as needed.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 4</small>
                          </div>
                          <h4>Final Delivery</h4>
                        </div>

                        <p>
                          Receive the finalized animated video walkthrough ready
                          for integration into your marketing materials or
                          online platforms.
                        </p>
                      </div>
                    </div>
                  </div>
                  {VideoItemAN?.length === 0 ? (
                    <></>
                  ) : (
                    <div className="part">
                      <div className="justify-between lg:flex">
                        <div>
                          <h6 className="sub-head">
                            Explore Some of Our Recent Animated Videos Projects
                          </h6>{" "}
                        </div>
                        <div className="flex justify-center ">
                          <div>
                            <NavLink
                              to="/Projects"
                              className="btn btn-secondary md:mt-5"
                            >
                              View more
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <ProjectCard
                        cardInfo={VideoItemAN}
                        info="video"
                      ></ProjectCard>
                    </div>
                  )}

                  <div className="part">
                    <div>
                      <div>
                        <h6 className="sub-head">Frequently Asked Questions</h6>{" "}
                      </div>
                      <Accordion item={itemAn}></Accordion>
                    </div>
                  </div>
                  <div className="part">
                    <div className="ani-banner">
                      <h5>
                        Bring Your Spaces to Life with our Animated Videos!
                      </h5>
                      <div className="flex justify-center">
                        <button
                          className="btn btn-primary"
                          onClick={BookDemoHandler}
                        >
                          Book A Demo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 services-grid" id="VideoShoot">
            <div className="accordion-header" onClick={toggleAccordionvs}>
              <span className="icon">{isOpenVs ? "-" : "+"}</span>
            </div>
            <div>
              <div
                className="services-accordion-head"
                onClick={toggleAccordionvs}
              >
                <h2 className="text-4xl">Video & Photo Shoot</h2>

                <h4>Capturing Life's Essence, Crafting Timeless Memories</h4>
              </div>
              {isOpenVs && (
                <div ClassName="mx-6">
                  <div className="mb-6 md:w-4/5 ">
                    <p>
                      Our Video & Photo Shoot service is your gateway to
                      preserving life's most precious memories and showcasing
                      your brand's unique essence. Immerse your audience in
                      captivating visuals that transcend words. Explore a world
                      where every frame tells a story and every click freezes a
                      moment in time.
                    </p>
                  </div>
                  <div className="grid lg:grid-cols-4 lg:pr-9">
                    <CardSimple
                      Item={VsProfessional}
                      img={require("../images/icon/video/Professional.png")}
                    />
                    <CardSimple
                      Item={VsCreative}
                      img={require("../images/icon/video/videoshoot.png")}
                    />
                    <CardSimple
                      Item={VsPost}
                      img={require("../images/icon/animation/NarrativeStorytelling.png")}
                    />
                    <CardSimple
                      Item={VsDelivery}
                      img={require("../images/icon/animation/Compatibility.png")}
                    />
                  </div>
                  <div className=" step">
                    <div>
                      <h6 className="sub-head"> How do we do it?</h6>{" "}
                    </div>
                    <div className="grid gap-2 lg:grid-cols-2 lg:pr-9">
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 1</small>
                          </div>
                          <h4>Initial Consultation</h4>
                        </div>

                        <p>
                          We start by understanding your goals and expectations,
                          allowing us to tailor our approach to meet your needs.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 2</small>
                          </div>
                          <h4>Creative Planning</h4>
                        </div>

                        <p>
                          Together, we brainstorm ideas, scout locations, and
                          develop a shot list that captures the essence of your
                          story.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 3</small>
                          </div>
                          <h4>Execution</h4>
                        </div>

                        <p>
                          On the day of the shoot, our team springs into action,
                          capturing every moment with precision and passion.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 4</small>
                          </div>
                          <h4>Artistic Editing</h4>
                        </div>

                        <p>
                          Our skilled editors transform raw footage into
                          polished masterpieces, weaving together visuals and
                          sound to create a compelling narrative.
                        </p>
                      </div>
                      <div className="setp-step">
                        <div className="flex items-center gap-2">
                          <div className="step-box">
                            <small> 5</small>
                          </div>
                          <h4>Final Delivery</h4>
                        </div>

                        <p>
                          Sit back and relax as we deliver the finished product
                          that ensures your memories are preserved for
                          generations to come.
                        </p>
                      </div>
                    </div>
                  </div>

                  {VideoItemVS?.length === 0 ? (
                    <></>
                  ) : (
                    <div className="part">
                      <div className="justify-between lg:flex">
                        <div>
                          <h6 className="sub-head">
                            Explore Some of Our Recent Video & Photo Shoot
                            Projects
                          </h6>{" "}
                        </div>
                        <div className="flex justify-center">
                          <div>
                            <NavLink
                              to="/Projects"
                              className="btn btn-secondary md:mt-5"
                            >
                              View more
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <ProjectCard
                        cardInfo={VideoItemVS}
                        info="video"
                      ></ProjectCard>
                    </div>
                  )}

                  <div className="part">
                    <div>
                      <div>
                        <h6 className="sub-head">Frequently Asked Questions</h6>{" "}
                      </div>
                      <Accordion item={itemVs}></Accordion>
                    </div>
                  </div>
                  <div className="part">
                    <div className="Vs-banner">
                      <h5>
                        Capture life's essence and craft timeless memories with
                        us!
                      </h5>
                      <div className="flex justify-center">
                        <button
                          className="btn btn-primary"
                          onClick={BookDemoHandler}
                        >
                          Book A Demo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>

      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <div className="p-">
          <iframe
            width="100%"
            height="500"
            src={selectedData}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>
      <Footer />
      <ModalBook
        isVisible={showBookDemo}
        onClose={() => setShowBookDemo(false)}
      />
    </section>
  );
};
