import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import logo from "../images/logo.png";
import facbook from '../images/icon/logo-facebook.png'
import twitter from '../images/icon//logo-twitter.png';
import insta from '../images/icon//logo-instagram.png';


export const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    return (
        <div className='footer'>
            <div className='dark-bg p-t-b sm:px-5 lg:px-20'>
                <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-6'>
                    <div className='col-span-2 footer-head'>
                        <img src={logo} alt="log" className='logo' />
                        <p>we aim to bring tangible value to your business and services, turning dreams into virtual realities.</p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <NavLink to="/" onClick={scrollToTop}>Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/Projects" onClick={scrollToTop}>Projects</NavLink>
                            </li>
                            <li>
                            <NavLink to="/#Clients">Clients</NavLink>
                            </li><li>
                                <NavLink to="/Contact" onClick={scrollToTop}>Contact Us</NavLink>
                            </li>

                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <NavLink to="/Services#AnimatedVideos">VR Tours</NavLink>
                            </li>
                            <li>
                                <NavLink to="/Services#AnimatedVideos">Animated Video</NavLink>
                            </li>
                            <li>
                                <NavLink to="/Services#VideoShoot" onClick={scrollToTop}>Video Shoot</NavLink>
                            </li><li>
                                <NavLink to="/Services#PhotoShoot" onClick={scrollToTop}>Photo Shoot</NavLink>
                            </li>

                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                            <NavLink to="/#Estat">Real estate</NavLink>
                            </li>
                            <li>
                            <NavLink to="/#Hospitality">Hospitality</NavLink>
                            </li>
                          

                        </ul>
                    </div>
                    <div className='flex gap-5 lg:justify-end sm:justify-start'>
                        <a href='https://www.youtube.com/@doorto360' target="_blank" ><img src={facbook} alt="" /></a>
                        <a href='https://www.instagram.com/doorto360/' target="_blank"><img src={insta} alt="" /></a>
                        
                    </div>
                </div>
                <div className='copy'>
                    <small>© 2024 doorto360 All rights reserved | Powered By: <a href='https://www.kreativesparkz.com/' target='_blank	'> Kreative Sparkz Ad Media </a> </small> 

                </div>
            </div>

        </div>
    )
}
